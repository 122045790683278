<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          tab="avatar"
          href="#"
          @click.prevent="showDependantPicker"
        >
          <ion-avatar class="avatar-container">
            <ion-img class="avatar" :src="activeDependant.avatar"></ion-img>
          </ion-avatar>
        </ion-tab-button>

        <ion-tab-button tab="diary" href="/diary">
          <ion-icon :icon="clipboardOutline" />
          <ion-label>{{ $t("Diary") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="calendar" href="/calendar">
          <ion-icon :icon="calendarOutline" />
          <ion-label>{{ $t("Calendar") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="services" href="/services">
          <ion-icon :icon="cartOutline" />
          <ion-label>{{ $t("Services") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="sidemenu">
          <ion-menu-toggle
            menu="start"
            autoHide="false"
            class="side-menu-toggle"
          >
            <ion-icon :icon="ellipsisHorizontal" />
            <ion-label>{{ $t("Options") }}</ion-label>
          </ion-menu-toggle>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    <ion-modal
      :is-open="showingDependantPicker"
      @onDidDismiss="hideDependantPicker"
    >
      <dependant-picker @close="hideDependantPicker"></dependant-picker>
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  IonAvatar,
  IonIcon,
  IonImg,
  IonLabel,
  IonMenuToggle,
  IonModal,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs
} from "@ionic/vue";
import { ref, computed } from "vue";
import {
  cartOutline,
  ellipsisHorizontal,
  calendarOutline,
  clipboardOutline
} from "ionicons/icons";
import DependantPicker from "@/components/DependantPicker.vue";
import { useStore } from "vuex";

export default {
  name: "Main",
  components: {
    DependantPicker,
    IonAvatar,
    IonIcon,
    IonImg,
    IonLabel,
    IonMenuToggle,
    IonModal,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs
  },
  setup() {
    const showingDependantPicker = ref(false);
    const showDependantPicker = () => (showingDependantPicker.value = true);
    const hideDependantPicker = () => (showingDependantPicker.value = false);
    const store = useStore();
    const activeDependant = computed(() => store.state.auth.activeDependant);
    return {
      cartOutline,
      ellipsisHorizontal,
      calendarOutline,
      clipboardOutline,
      showingDependantPicker,
      showDependantPicker,
      hideDependantPicker,
      activeDependant
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";

ion-tab-bar {
  // padding: 5px 0 20px;
  height: 56px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.24776);
  --border: 1px solid transparent;

  ion-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }

  ion-icon {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .avatar-container {
    display: flex;
  }

  .avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid transparent;
    margin: auto;
  }

  .tab-selected {
    .avatar {
      border-color: $color-orange;
    }
  }
}
ion-menu-toggle.side-menu-toggle {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
</style>
